'use strict';

/*******************************************************************************************/
class UxrEeMap extends React.Component {
	constructor(props) {
		super(props);
		this.defaultValue = {
			'requestId': -1,
			'latLng': [51.3396955, 12.3730747],
			'address': 'Leipzig, Germany'
		};
		//this.state = {};
		
		this.onJQueryMount = this.onJQueryMount.bind(this);
		this.onAddressKeyup = this.onAddressKeyup.bind(this);
		this.onAddressSubmit = this.onAddressSubmit.bind(this);
		this.onLocationChange = this.onLocationChange.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		
		return (
			<div {...this.props.htmlProps}>
				<div className="inputter inputter_map MVTctrl=MVT_terminal=FformLine" ref={this.onJQueryMount}>
					<input type="text" onKeyDown={this.onAddressKeyup} className="inputter inputter_mapAddress" />
					<input type="button" value="Suche" className="uxr_actionButton" />
					<div className="inputter_mapImage"></div>
				</div>
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onJQueryMount(node) {
		this.jQ = jQuery(node);
	}
	
	/*---------------------------------------------------------------------*/
	componentDidMount() {
		this.jQ
			.find('input[type="button"]')
			.on('click', this.onAddressSubmit)
			;
		
		this.map = MAP.instance(
			this.jQ.find('.inputter_mapImage'), 
			this.onLocationChange
			);
		
		let value = this.props.value || this.props.defaultValue || this.defaultValue;
		this.map.set(value);
	}
	
	/*---------------------------------------------------------------------*/
	componentWillUnmount() {
		this.map.destroy();
		delete this.map;
	}
	
	/*---------------------------------------------------------------------*/
	onAddressKeyup(event) {
		if (event.keyCode == 13) {
			this.onAddressSubmit(event);
		}
	}
	
	/*---------------------------------------------------------------------*/
	onAddressSubmit(event) {
		let val = this.jQ
			.find('input.inputter_mapAddress')
			.val()
			;
		
		this.map
			.setAddress(val)
			;
		
		//bubbles up at onLocationChange eventually
	}
	
	/*---------------------------------------------------------------------*/
	onLocationChange(map, location) {
		this.jQ
			.find('input.inputter_mapAddress')
			.val(location.address || '')
			;
		this.props.onChange(this, {value: location});
	}
	
} //class
